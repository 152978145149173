@import 'styles/libs';
@import 'styles/texts';

.cardWrapper {
  display: inline-block;
}

.base {
  display: grid;
  row-gap: 8px;
  padding: 16px;
  color: var(--gray60-alpha);

  @include isSp {
    row-gap: 4px;
    padding: 8px 12px;
  }
}

.listItem {
  display: grid;
  row-gap: 8px;
}

.item {
  // PopoverMenuをFigmaデザインに合わせるときにtightにするため、今はnormalを許容
  @include typography('normal', 14px, bold);

  width: 100%;

  @include isSp {
    padding: 8px 12px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.button {
  justify-content: flex-start;
}

.dividerWrapper {
  width: 100%;
}
